.flex {
	display: flex;
	&__row {
		flex-direction: row;
	}
	&__column {
		flex-direction: column;
	}
	&-grow {
		flex-grow: 1;
	}
	&.justify {
		&-center {
			justify-content: center;
		}
		&-start {
			justify-content: flex-start;
		}
		&-end {
			justify-content: flex-end;
		}
	}
	&.align {
		&-center {
			align-items: center;
		}
		&-start {
			align-items: flex-start;
		}
		&-end {
			align-items: flex-end;
		}
	}
	&.space {
		&-around {
			justify-content: space-around;
		}
		&-evenly {
			justify-content: space-evenly;
		}
		&-between {
			justify-content: space-between;
		}
	}
}