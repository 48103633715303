@import '../../../../scss/variables';

.asset-detail {
  z-index: 3;
  position: absolute;
  min-width: 100vw;
  bottom: 0;
  left: 0;
  background-color: $--neutral-white;
  border-radius: 20px 20px 0 0;

  &__head {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f1f7ff;
    padding: 16px;
  }

  &__body {
    padding: 16px;
  }
  &__foot {
    padding: 16px;
    border-top: 1px solid #f1f7ff;
    float: right;
  }

  &__title {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.25;
    color: $--neutral-grey;
  }
}
