@import "./variables";

.form-group {
  margin-bottom: 16px;

  .form-label {
    color: #373737;
    font-size: 12px;
    font-weight: 600;
  }

  input[type="password"]::-ms-clear,
  input[type="password"]::-ms-reveal {
    display: none;
  }

  .form-password-input {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    border: solid 1px #fff;
    position: relative;
    display: flex;
    align-items: center;

    .custom-password-icon {
      color: #fff;
      display: flex;
      justify-self: center;
      align-items: center;
      position: absolute;
      top: 50%;
      right: 8px;
      transform: translateY(-50%);
      cursor: pointer;
    }

    input {
      background-color: transparent;
      color: #fff;
      width: 100%;
      height: inherit;
      border: none;
      outline: none;
      padding: 12px 8px;
      &::placeholder {
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }

  .form-text-input,
  .form-select-input,
  .form-control,
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    height: 40px;
  }

  .form-textarea-input {
    width: 100%;
    border-radius: 4px;
    border: solid 1px #c4c4c4;
    display: flex;
    align-items: center;

    textarea {
      background-color: transparent;
      color: #373737;
      width: 100%;
      height: 118px;
      border: none;
      outline: none;
      padding: 12px 8px;
      resize: none;
    }
  }

  .form-input-error {
    border: solid 1px$--neutral-wine-red;

    input {
      &::placeholder {
        color: $--neutral-wine-red;
      }
    }
  }

  .error-msg {
    color: $--neutral-wine-red;
    font-size: 12px;
    font-weight: 600;
    margin-top: 8px;
  }
}

.form-submit-btn {
  height: 48px;
  background-color: #fff;
  border: none;
  border-radius: 4px;
  padding: 14px 12px;
  color: #2b67b3;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 12px;

  &:focus {
    outline: none;
  }
}

.form-cancel-btn {
  background-color: transparent;
  border: none;
  padding: 14px 12px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #fff;

  &:focus {
    outline: none;
  }
}

.form-text-input {
  width: 100%;
  height: 36px;
  border-radius: 4px;
  border: solid 1px #c4c4c4;
  display: flex;
  align-items: center;

  input {
    background-color: transparent;
    color: #373737;
    width: 100%;
    height: inherit;
    border: none;
    outline: none;
    padding: 12px 8px;
  }
}

.form-select-input {
  border: solid 1px #c4c4c4;
  height: 36px;
  border-radius: 4px;
  position: relative;
  display: flex;
  align-items: center;

  .custom-arrow {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-50%);
    pointer-events: none;
  }

  select {
    border-radius: 4px;
    width: 100%;
    height: inherit;
    padding: 0 8px;
    border: none;
    background-color: transparent;
    font-size: 14px;
    color: #373737;
    appearance: none;
    cursor: pointer;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.form-date-input {
  width: 100%;
  border: solid 1px #c4c4c4;
  height: 36px;
  border-radius: 4px;
  padding: 0 8px;
  display: flex;
  align-items: center;

  input {
    width: 100%;
    height: inherit;
    padding: 0;
    border: none;
    background-color: transparent;
    font-size: 14px;
    color: #373737;
    appearance: none;
    cursor: pointer;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.form-text-input,
.form-select-input,
.form-date-input,
.form-textarea-input {
  &:focus-within {
    border-color: #2b67b3;
  }
  input,
  textarea {
    &::placeholder {
      color: rgba(255, 255, 255, 0.5);
    }

    &:focus {
      box-shadow: none;
    }

    &:focus::placeholder {
      color: transparent;
    }

    &::placeholder {
      color: #868585;
      font-size: 14px;
    }
  }
}
.form-text-input,
.form-select-input,
.form-control,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  color: #373737;
  &:focus {
    border-color: #2b67b3 !important;
    box-shadow: 0 0 0 0.2rem rgb(43 103 179 / 25%);
  }
}
