@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap");

* {
  font-family: "Open Sans", sans-serif;
}

.c-body {
  background-color: #fafcfe;
  padding-bottom: 80px;
}
.body-container {
  background-color: #fff;
  padding: 16px 16px 48px 16px;
  box-shadow: 0 0 13px 0 rgb(82 63 105 / 10%);
}

@import "antd/dist/antd.css";
// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

// Some temp fixes
@import "fixes";

// If you want to add something do it here
@import "custom";

// Import form styles
@import "table";

@import "form";

@import "buttons";

@import "inputs";
@import "modal";
@import "flex";
