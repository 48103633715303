@import "./variables";

.button {
  min-width: 40px;
  // width: 40px;
  height: 40px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 10px 12px;
  border-radius: 4px;

  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;

  background-color: transparent;
  border: none;
  outline: none;
}

.btn,
.button {
  font-weight: 600;
  &__outline {
    &--primary {
      color: #2b67b3;
      border: solid 1px #2b67b3;
      background-color: #fff;
      &:hover,
      &:active {
        background-color: #2b67b3;
        color: #fff;
        .btn-icon {
          filter: brightness(0) invert(1);
        }
      }
    }
    &--success {
      color: #00a560;
      border: solid 1px#00a560;
      background-color: #fff;
      &:hover,
      &:active {
        background-color: #00a560;
        color: #fff;
        .btn-icon {
          filter: brightness(0) invert(1);
        }
      }
    }
  }

  &--primary {
    color: #fff;
    border-color: #2b67b3;
    background-color: #2b67b3;
    &:hover,
    &:active {
      color: #fff;
      opacity: 0.9;
      background-color: #2b67b3;
      .btn-icon {
        filter: brightness(0) invert(1);
      }
    }
  }

  &--danger {
    color: #fff;
    background-color: #ff3850;
  }

  &--success {
    color: #fff;
    background-color: $--semantic-green;
  }

  &--secondary {
    color: #2b67b3;
    background-color: transparent;
  }

  &--disabled {
    color: #2b67b3;
    background-color: #c4c4c4;
  }

  &--border-less {
    background-color: transparent;
    border: none;
  }
  &--none-outlined {
    &, &:focus, &:active {
      outline: none;
    }
  }

  & &__text {
    height: 16px;
    flex-grow: 0;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: center;
  }

  &:active,
  &:focus {
    border: none;
    outline: none;
  }

  &-icon {
    &:hover {
      filter: invert(39%) sepia(14%) saturate(2760%) hue-rotate(174deg)
        brightness(93%) contrast(94%);
    }

    &.button-icon__danger:hover {
      filter: invert(50%) sepia(57%) saturate(2919%) hue-rotate(323deg)
        brightness(104%) contrast(110%);
    }

    &--white {
      filter: brightness(0) invert(1);
    }
  }
}
