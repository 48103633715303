@import '../../../../scss/variables';

.asset-item {
  display: flex;
  align-items: center;
  padding: 6px 8px;
  border-radius: 8px;
  &:hover {
    border: solid 1px $--semantic-blue-rayola-lightest;
  }
  cursor: pointer;
}

.asset-img {
  width: 50px;
  height: 50px;
  align-self: stretch;

  img {
    width: 100%;
    height: 100%;
  }
}

.asset-text {
  margin-left: 8px;
}

.asset-name {
  font-size: 12px;
  font-weight: 600;
  color: $--neutral-black;
}

.asset-id {
  font-size: 10px;
  line-height: 1.6;
  color: $--neutral-grey;
}

.asset-scan-img {
  width: 24px;
  height: 24px;
  margin-left: auto;
}
