@import "../../scss/variables";

.uploader {

	display: block;
	font-size: 18px;
	width: calc(100%);
	height: calc(100%);
    border: none;
    text-transform: none;

	inset: 0;;
    opacity: 0;
	flex-grow: 1;

	&__box {
		
		& .uploader__button--border-less.uploader__button {
			height: 5rem;
			border: none;
			& .uploader__preview__container {
				// padding: 0.5rem 0;
				padding: 0.5rem;
			}
		}

	}

	&__container {

		width: 3.625rem;
		height: 3.625rem;
		border-radius: 4px;
		border: dashed 1px $--primary-tlr-blue;
		background-image: url("../../assets/icons//plus-black.svg");
		background-repeat: no-repeat;
		background-position: center;

		display: flex;
		gap: 10px;
		align-items: center;
		justify-content: center;

		&:hover {

			filter: invert(39%) sepia(14%) saturate(2760%) hue-rotate(174deg) brightness(93%) contrast(94%);
			border: dashed 1px red;
		}
	}

	&__drop-zone {
		
		// height: 15rem;
		width: calc(100%);
		position: absolute;
		inset: auto;
	}

	&__button {

		width: calc(100%);
		// min-height: 240px;
		// height: auto;
		height: 7.5rem;
		// position: absolute;
		display: flex;
		background-color: transparent;
		align-self: stretch;
		flex-grow: 0;
		border-radius: 4px;
		border: dashed 1px #2b67b3;
		flex-direction: column;
		align-items: stretch;
		justify-content: center;
		gap: 8px;
		padding: 0;

		&__close {

			display: flex;
			width: 14px;
			height: 14px;
			padding: 2px;
			object-fit: contain;
			outline: none;
			border-radius: 4px;
			border: solid 1px #c4c4c4;
			background-color: #ffffff;
			
			position: absolute;
			top: -7px;
			right: -7px;

			& > img {

				width: 100%;
				height: 100%;
			}
			
			&:hover, 
			&:focus, 
			&:active {

				outline: none;
				border: solid 1px $--neutral-wine-red;

				& > img{

					filter: invert(50%) sepia(57%) saturate(2919%) 
					hue-rotate(323deg) brightness(104%) contrast(110%);
				}
			}
		}

		&, &:focus, &:active {

			outline: none;
		}
	}
	
	&__text {
		
		height: 16px;
		flex-grow: 0;
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.33;
		letter-spacing: normal;
		text-align: center;
		color: #373737;
		
		&--emphasized {

			color: #2b67b3;
		}
	}

	&__sub-text {
		
		display: flex;
		inset: 17rem 0 0;
		padding: 0.5rem 0 0.875rem;
	}

	&__preview {

		// height: 100%;
		font-size: 18px;
		width: 100%;
		display: flex;
		align-items: flex-start;
		justify-content: flex-end;

		border: solid 1px $--neutral-grey-light;

		&__container {
			width: 100%;
			height: 100%;
			display: flex;
			flex-grow: 1;
			flex-wrap: wrap;
			align-items: center;
			flex-direction: column;
			justify-content: center;
			gap: 0.5rem;
			padding: 0.75rem;
			
			overflow: hidden;
			position: relative;

			&--has-children {

				width: 100%;
				flex-grow: 1;
				align-items: flex-start !important;
				flex-direction: row !important;
				justify-content: flex-start !important;
				gap: 0.75rem 0.875rem;
				overflow-y: scroll;
				overflow-x: hidden;

				&::-webkit-scrollbar {
					width: 4px;
					background-color: transparent;
				}

				&::-webkit-scrollbar-button {
				
					display: none;
				}

				&::-webkit-scrollbar-thumb {
					border-radius: 10px;
					-webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
					background-color: #ccc;
					outline: 1px solid #e7eaf0;
				}

				&::-webkit-scrollbar-track {
					border-radius: 10px;
					background-color: transparent;
				}
				
				&.uploader__preview__container--un-scroll {
					overflow: hidden;
				}
			}
			&--flex {
				&-start {
					align-items: flex-start;
				}
				&-end {
					align-items: flex-end;
				}
			}
		}

		&__child {
			
			font-size: 13px;
			width: 3.625rem;
			max-width: 3.625rem;
			min-height: 3.625rem;
			margin: 0;
			padding: 0;
			border-radius: 5px;
			background-image: none;
			color: $--neutral-grey !important;

			display: flex;
			position: relative;
			align-items: center;
			justify-content: flex-start !important;
			flex-direction: column;
			gap: 0.125rem;

			& > div:not(.uploader__preview__child__image) {
				
			}

			&__info {
				
				display: flex;
				align-self: flex-start;
				overflow: hidden;
			}

			&__image {

				width: 3.625rem;
				height: 3.625rem;
				border-radius: 4px;
				padding: 4px;
				border: solid 1px $--neutral-lightest-grey;
				&:hover > .uploader__preview__child__button {

					display: flex;
				}

				& > img {
					
					width: 3.625rem;
					height: 3.625rem;
					width: 100%;
					height: 100%;
					border-radius: 4px;
					object-fit: contain;
				}
			}

			&__text {

				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				max-width: 3.625rem;

				&--temporary {
					
					color: $--neutral-grey-medium !important;
				}
			}

			&__button {

				width: 3.625rem;
				height: 3.625rem;
				color: #333;
				border-radius: 0.25rem;  
				background-color: rgb(4,24,38, 0.4);

				position: absolute;
				inset: 0;
				cursor: pointer;
				
				display: none;
				justify-content: center;
				align-items: center;
				gap: 0.5rem;

				&__text {
					color: #fff;
					font-size: 14px;
					font-weight: 600;
				}

				&__preview:hover {

					filter: invert(39%) sepia(14%) saturate(2760%) hue-rotate(174deg) brightness(93%) contrast(94%);
				}

				&__remove:hover {

					filter: invert(50%) sepia(57%) saturate(2919%) hue-rotate(323deg) brightness(104%) contrast(110%);
				}

				& button {

					padding: 0;
					border: none;
					background: transparent;
					opacity: 1;

					&:active,
					&:focus {

						outline: none;
					}
				}

				&--show {

					display: flex;
				}
			}
		}
	}
}

.preview-image {

	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	inset: 3.0625rem 0;
	
	width: 100%;
	height: calc(100vh - 6.125rem);
	
	&__list-items { 
		height: calc(100vh - 4.375rem - 6.125rem); 
	}

	& > img {

		height: 100%;
		object-fit: contain;
	}

	&__button {
		border: none;
		background: rgb(69 69 69 / 40%);
		border-radius: 50%;
		width: 2.5rem;
		height: 2.5rem;
		padding: 0;
		
		display: flex;
		align-items: center;
		justify-content: center;

		& > img {

			width: 1.5rem;
			height: 1.5rem;
		}

		&:hover {

			background-color: #3c4b64;
		}

		&:active,
		&:focus {

			outline: none;
			border: none;
		}

		&-close {
			position: absolute;
			inset: 0.5rem 1rem auto auto;
			z-index: 10;
		}

		&-left {
			position: absolute;
			inset: 50px auto 80px 0;
			z-index: 10;
		}
		&-right {
			position: absolute;
			inset: 50px 0 80px auto;
			z-index: 10;
		}

		&__wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			height: calc(100% - 50px - 80px);
			width: 6rem;
			padding: 1rem;
		}
	}

	&__background {

		padding: 3.0625rem 0;
		
		&__list-items {
			padding: 3.0625rem 0 7.4475rem;
		}

		&--overlay {
			background: #000;
			position: absolute;
			inset: 0;
		}
	}

	&__carousel {
		display: flex;
		grid-gap: 0.5rem;
		gap: 1rem;
		padding: 0.5rem 0.25rem;
		justify-content: center;
		align-items: center;
		position: absolute;
		inset: auto 0 0;
	}
}