@import "./variables";
.custom-table {
  &__table {
    width: 100%;
    flex-grow: 0;
    padding: 0;
    border-spacing: 0;
    border: solid 1px #ebf0ff;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    background-color: #fff;

    border-collapse: separate !important;
    border-spacing: 0;
  }

  &__thead,
  &__rows {
    height: 40px;
    background-color: #eff3f7;

    &,
    & > tr {
      padding: 8px 12px;
    }

    & > tr > th {
      padding: 0.75rem;
    }

    &__text {
      flex-grow: 1;
      line-height: 1.14;
      color: $--neutral-grey;
    }

    & > td,
    & > tr > th {
      & > div {
        display: flex;
      }

      & > div.custom-table__rows__text {
        flex-grow: 1;
      }
    }
  }

  &__thead {
    &--whiten,
    &--whiten > tr:hover {
      background-color: #fff;
    }
    &__text {
      height: 16px;
      flex-grow: 1;
      font-size: 12px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: left;
      color: #626262;

      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // width: 0;
    }
  }

  &__rows {
    height: 48px;
    background-color: #fff;

    & > td {
      border-bottom: solid 0.0625rem #ebf0ff;

      &:first-of-type {
        border-left: solid 0.125rem #fff;
      }
    }

    &.custom-table__rows--selected > td {
      border: none;
    }

    &:hover,
    &--hovered {
      &:not(.custom-table__rows--blank) {
        background-color: #f1f7ff;

        & > td:first-of-type {
          border-left: solid 0.125rem #2b67b3;
        }
      }
    }

    & td:last-of-type {
      & > .custom-table__rows__text {
        display: flex;
        justify-content: center;
        height: 20px;

        & > button {
          padding: 0;
          width: 24px;
          height: 24px;
        }
      }
    }

    & > td {
      padding: 0.75rem;
      padding: 0.375rem 0.5rem;

      & > div.custom-table__rows__text {
        display: flex;
        flex-grow: 1;
        align-items: center;
        gap: 8px;
      }
    }

    &__text {
      flex-grow: 1;
      font-size: 14px;
      line-height: 1.14;
      position: relative;

      & > button {
        background-color: transparent;
      }
    }

    &__bar {
      flex-grow: 1;
      display: flex;
      justify-content: center;

      & .custom-table__rows__text {
        width: auto;
      }
    }

    &__actions {
      display: flex;
      justify-content: center;
      overflow: unset;
      align-items: center;

      & > button {
        display: none;

        background-color: transparent;
        border: none;
        outline: none;

        padding: 0.5rem;

        &:hover {
          filter: invert(39%) sepia(14%) saturate(2760%) hue-rotate(174deg)
            brightness(93%) contrast(94%);
        }
      }
    }

    &--selected {
      background-color: #f1f7ff;

      & > td:first-of-type {
        border-left: solid 0.125rem #2b67b3;
      }
    }

    &__actions--show {
      & > button {
        display: flex;
      }
    }
  }

  &__toggler {
    border: none;
    padding: 0;
    position: absolute;
    inset: 0 12px 0 auto;

    &:focus,
    &:active {
      outline: none;
    }
  }

  &__tfoot {
    min-height: 3.5rem;
    height: 3.5rem;
    padding: 0.75rem;
  }
}
.list-empty-message {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #868585;
}
* {
  
  &::-webkit-scrollbar {
    height: 4px;
    width: 4px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-button {
  
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    background-color: #ccc;
    outline: 1px solid #e7eaf0;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: transparent;
  }
}
@media (min-width: #{$ss_sm}) and (max-width: #{$ss_lg}) {
  .myassets-list__rows__actions > button {
    display: unset !important;
  }
}

@media (max-width: #{$ss_lg}) {
  .table-responsive {
    overflow-x: auto;
    overflow-y: hidden;
  }
}
