.modal {
  border-radius: 8px;
  box-shadow: 2px 4px 10px 0 rgba(13, 11, 80, 0.05);

  &-dialog {
    // margin: 3.291667% 16.875%;
    // height: auto;

    // max-width: unset !important;
    // display: flex;
    // align-items: flex-start;
  }

  &--delete {
    & > .modal-dialog {
      margin: 7.291667% 30%;
      height: auto;
      display: flex;
      align-items: flex-start;
    }
  }

  &-title {
    height: 20px;
    flex-grow: 0;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: left;
    color: #373737;
  }

  &-body {
    padding: 0.5rem 0.75rem;
    min-height: 2.5rem;

    &__delete-form {
      min-height: 2.5rem;
      display: flex;
      align-items: center;
    }
    &::-webkit-scrollbar {
      width: 4px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-button {
    
      display: none;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
      background-color: #ccc;
      outline: 1px solid #e7eaf0;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: transparent;
    }
  }

  &-label {
    &__text {
      height: 1.25rem;
      margin: 0 0 0.5rem;
      flex-grow: 0;
      font-size: 12px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: left;
      color: #373737;

      word-break: break-all;
      white-space: break-spaces;

      &--red {
        color: #ff3850;
      }
    }
  }

  &-input {
    &__text {
      height: 2.5rem;
      width: 100%;
      flex-grow: 0;
      padding: 0.75rem 0.5rem;
      border-radius: 0.25rem;
      border: solid 1px #c4c4c4;

      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.14;
      letter-spacing: normal;
      text-align: left;
      color: #373737;

      &,
      &:active,
      &:focus {
        outline: none;
      }
    }

    &-text-area {
      height: 7.5rem;
      min-height: 7.5rem;
      max-height: 7.5rem;
    }
  }

  &--transparent {
    & .modal-content {
      background-color: transparent;
    }
  }

  &--preview-image {
    & .modal-header,
    & .modal-footer {
      border: none;
    }

    & > .modal-dialog {
      margin: 0 !important;
      padding: 0 !important;
      height: 100vh !important;
      max-width: unset !important;


      & > .modal-content {
        height: 100% !important;
      }
    }
  }
}

.container {
  margin: 0;
  padding: 0;
}

.row {
  margin: 0 0 0.25rem;
  height: max-content;
}

.col {
  height: 2.125rem;
  padding: 0.5rem 0 0;
}

.cell {
  padding: 0.25rem 0.75rem;
  height: max-content;
}

.spacing {
  &-38 {
    margin: 0 0 2.375rem;
  }

  &-54 {
    margin: 0 0 3.375rem;
  }
}

select.modal-input__text {
  -webkit-appearance: none;
  appearance: none;
  padding-top: 0;
  padding-bottom: 0;
}

@import "../../scss/mixins";
@import "../../scss/variables";
@media (min-width: $ss_lg) {
  .col {
    &-custom {
      &-lg {
        &--3-24 {
          flex: 0 0 calc(300% / 24);
          max-width: calc(300% / 24);
        }
      }
    }
  }
}
@media (min-width: $ss_sm) and (max-width: 548px) {
  .uploader {
    &__btn {
      margin: 0 0 4rem;
    }

    &__sub-text {
      inset: 18.5rem 0 0 !important;
      align-items: baseline !important;
      height: 52px !important;
    }
  }
}
@media (max-width: 1023px) {
  .uploader {
    // width: calc(100% - 1.5rem) !important;
    // inset: 2rem 0 0 0.75rem !important;

    &__btn {
      margin: 0 0 2.5rem;
      position: static !important;
      width: calc(100%) !important;
    }

    &__sub-text {
      // height: 2.5rem !important;
      // padding: 0.75rem !important;

      // padding: 0.5rem 0 0.875rem;
    }
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  .col {
    &-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }

    &-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}

@media (min-width: 992px) and (max-width: 1032px) {
  .select-custom__text {
    padding: 0.75rem 2rem 0.75rem 0.5rem !important;
  }

  .modal {
    &-dialog {
      margin: 7.291667% 15% !important;
    }
  }
}

@media (min-width: 1032px) and (max-width: 1149) {
  .select-custom__text {
    padding: 0.75rem 2rem 0.75rem 0.5rem !important;
  }
}

@media (min-width: 1024px) and (max-width: 1430px) {
  .modal {
    &-label__text {
      min-height: 1.75rem !important;
      height: 1.75rem !important;
    }

    &-input-file {
      height: 16.25rem !important;
    }
  }

  .uploader,
  .uploader__button {
    &__sub-text {
      // height: 2.5rem !important;
      // inset: 18.5rem 0 0 !important;
      // padding: 0 0.75rem !important;
      // margin: 0.75rem 0 0;
    }
  }
}

@media (max-width: 1024px) {
  .modal-position--absolute {
    margin-bottom: 280px !important;
  }

  .modal-dialog {
    max-width: calc(100% - 4rem);
    margin: 4rem 2rem !important;
  }
}
