// Variable overrides
// screen width
$min-width: 320px;
$ss_xm: 375px;
$ss_sm: 480px;
$ss_md: 768px;
$ss_lg: 1024px;
$ss_xl: 1336px;

// color
$--neutral-grey: #373737;
$--neutral-black: #021115;
$--neutral-white: #ffffff;
$--neutral-background: #f5f5f5;
$--neutral-primary-blue-dark: #0d0b50;
$--primary-tlr-blue: #0e4f91;
$--semantic-blue-rayola-lightest: #b2d3ff;
$--light-blue: #fafcfe;
$--table-blue: #f1f7ff;
$--semantic-blue-rayola-light: #4595ff;
$--hover-blue: #ebfaff;
$--neutral-grey-medium: #868585;
$--semantic-blue: #2b67b3;
$--semantic-blue-rayola-lightest: #b2d3ff;
$--neutral-grey-light: #c4c4c4;
$--neutral-lightest-grey: #e5e4e4;
$--neutral-blue-25: #ebf0ff;
$--neutral-wine-red: #af1427;
$--semantic-red-light: #ff7485;
$--semantic-green: #00a560;

$--status-mint: #43bdd4;
$--status-light-mint: #d9f2f6;
$--status-orange: #ffa761;
$--semantic-orange: #ff961a;
$--status-light-orange: #ffeddf;
$--status-pink: #ff8c9d;
$--status-light-pink: #ffe8eb;

$--semantic-green: #00a560;
$--semantic-green: #00a560;
$--semantic-lightest-green: #66c9a0;
