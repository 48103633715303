.dropdown {
  position: relative;
  color: #333;
  cursor: default;
  height: inherit;
  .arrow {
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    content: " ";
    display: block;
    height: 0;
    margin-top: 0.3rem;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(-50%, -100%);
    width: 0;
  }
  .arrow-up {
    border-color: transparent transparent #999;
    border-width: 0 5px 5px;
  }
  .control {
    height: inherit;
  }
  .selected-value {
    line-height: 1.5;
    font-size: 1rem;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 0 28px 0 8px;
    border-radius: 4px;
    box-sizing: border-box;
    cursor: pointer;
    outline: none;
    transition: all 200ms ease;
    width: 100%;
    height: inherit;
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &:hover,
    &:focus {
      outline: none;
      border: solid 1px #0e4f91;
    }
    & p,
    & div {
      margin: 0;
      //padding: 0 0 0 11px;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.14;
      letter-spacing: normal;
      height: 100%;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      display: flex;
      align-items: center;
    }
  }
  .options {
    display: none;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 2px 4px 10px 0 rgba(13, 11, 80, 0.06);
    box-sizing: border-box;
    margin-top: -1px;
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    width: 100%;
    z-index: 1000;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      width: 3px;
      scroll-behavior: smooth;
    }
    &::-webkit-scrollbar-track {
      background-color: rgb(226, 225, 225);
      border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background-clip: padding-box;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
    .option {
      box-sizing: border-box;
      color: #868585;
      cursor: pointer;
      display: block;
      gap: 8px;
      padding: 14px 12px;
      font-size: 12px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      box-shadow: inset 0 -1px 0 0 #f1f7ff;
      .optional {
        p {
          margin: 0;
          margin-bottom: 0;
        }
        & p:first-of-type {
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.14;
          letter-spacing: normal;
          text-align: left;
          color: #373737;
        }
        & p:nth-child(2) {
          font-size: 12px;
          color: #868585;
        }
      }
      &:hover {
        background-color: #f2f9fc;
        color: #333;
      }
    }
    .selected {
      background-color: #f2f9fc;
      color: #333;
    }
  }
  .search {
    display: none;
    background-color: #fff;
    border: solid 1px #e5e4e4;
    box-sizing: border-box;
    margin-top: -1px;
    max-height: 200px;
    overflow-y: auto;
    width: 100%;
    z-index: 1000;
    -webkit-overflow-scrolling: touch;
    box-shadow: 2px 4px 10px 0 rgba(13, 11, 80, 0.06);
    margin-top: 0.2rem;
    border-radius: 4px;
    position: relative;
    & input {
      width: 100%;
      padding: 9px 32px 10px 0;
      padding-left: 2.75rem;
      border-radius: 4px;
      outline: none;
      border: none;
      &:focus {
        border: solid 1px #0e4f91;
      }
    }
    .search-icon {
      position: absolute;
      top: 50%;
      left: 1rem;
      transform: translate(-20%, -50%);
    }
    .close-icon {
      position: absolute;
      top: 50%;
      right: 0.5rem;
      transform: translate(-20%, -50%);
      cursor: pointer;
    }
  }
  .open {
    display: block;
  }
  .noSearch {
    display: none;
  }
  .more {
    text-align: center;
    color: #0e4f91 !important;
    padding: 10px 12px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
  }
  
  &.outline-red {
    & > .control {
      & > .selected-value {
        border: solid 1px #af1427 !important;
      }
    }
  }
}
