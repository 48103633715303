.loader-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-image: linear-gradient(to bottom, #003f6c, #4596a8);

  .logo-image {
    width: 72px;
    height: 72px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
