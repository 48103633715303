@import 'variables';

@mixin below {
  @media (max-width: #{$ss_sm - 1px}) {
    @content;
  }
}

@mixin mobile-min {
  @media (min-width: #{$min-width}) and (max-width: #{$ss_md - 1px}) {
    @content;
  }
}

@mixin mobile {
  @media (min-width: #{$ss_sm}) and (max-width: #{$ss_md - 1px}) {
    @content;
  }
}

@mixin mobile-ipad {
  @media (min-width: #{ss_sm}) and (max-width: #{$ss_lg - 1px}) {
    @content;
  }
}

@mixin mobile-above {
  @media (min-width: #{$ss_sm}) {
    @content;
  }
}

@mixin ipad {
  @media (min-width: #{$ss_md}) and (max-width: #{$ss_lg - 1px}) {
    @content;
  }
}

@mixin ipad-ipad-pro {
  @media (min-width: #{$ss_md}) and (max-width: #{$ss_xl - 1px}) {
    @content;
  }
}

@mixin ipad-above {
  @media (min-width: #{$ss_md}) {
    @content;
  }
}

@mixin ipad-pro {
  @media (min-width: #{$ss_lg}) and (max-width: #{$ss_xl - 1px}) {
    @content;
  }
}

@mixin ipad-pro-above {
  @media (min-width: #{$ss_lg}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$ss_xl}) {
    @content;
  }
}

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
