@import "../../scss/variables";

.tabs {
  &__container {
    display: flex;
    gap: 0.5rem;

    margin: 0.5rem 0 1rem;
  }

  &__icon {
    width: 1.25rem;
    height: 1.25rem;
    object-fit: contain;

    &--active {
      filter: invert(34%) sepia(79%) saturate(566%) hue-rotate(175deg) brightness(70%) contrast(94%);
    }
  }

  &__item {
    cursor: pointer;
    height: 2.5rem;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    gap: 0.5rem;
    background-color: $--neutral-white;

    &--active {
      box-shadow: inset 0 -0.125rem 0 0 $--semantic-blue;
    }
  }

  &__text {
    height: 1rem;
    flex-grow: 0;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: $--neutral-grey-medium;

    &--active {
      color: $--semantic-blue;
    }
  }
}
