@import "../../scss/variables";

.table {
  width: 100%;
  flex-grow: 0;
  padding: 0;
  border-spacing: 0;
  border: solid 1px #ebf0ff;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  box-shadow: 2px 4px 10px 0 rgba(13, 11, 80, 0.05);
  background-color: #fff;

  border-collapse: separate !important;
  border-spacing: 0;

  & thead th,
  & th,
  & td {
    vertical-align: inherit;
    border: none;
  }

  &__thead {
    & tr > th {
      &:first-of-type {
        & > div > .table__thead__text {
          text-align: center;
        }
      }
    }
  }

  &__thead,
  &__rows {
    height: 40px;

    & tr.table__rows,
    & tr {
      background-color: #eff3f7;
    }

    &,
    & > tr {
      padding: 8px 12px;
    }

    & > tr > th {
      padding: 0.75rem;
    }

    &__text {
      flex-grow: 1;
      line-height: 1.14;
    }

    &__text {
      height: 16px;
      font-size: 12px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: left;
      color: #626262;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &--text {
        &-center {
          text-align: center;
        }

        &-right {
          text-align: right;
        }
      }

      &--highlight {
        height: 1.5rem;
        display: flex;
        justify-content: center;
        padding: 4px;
        border-radius: 1rem;

        &-green {
          background-color: #e9fff6;
          color: #66c9a0;
        }
        &-grey {
          background-color: #f5f5f5;
          color: #868585;
        }
        &-mint {
          background-color: #d9f2f6;
          color: #43bdd4;
        }
        &-orange {
          background-color: #ffeddf;
          color: #ffa761;
        }
        &-pink {
          background-color: #ffe8eb;
          color: #ff8c9d;
        }
      }
    }

    & > td,
    & > tr > th {
      & > div {
        display: flex;
      }

      & > div.table__rows__text {
        flex-grow: 1;
      }
    }
  }

  &__rows {
    height: 48px;
    background-color: #fff;

    &:not(.table__rows--blank) > td {
      border-bottom: solid 0.0625rem #ebf0ff;

      &:first-of-type {
        border-left: solid 0.125rem transparent;

        & > div > .table__rows__text {
          text-align: center;
        }
      }
    }

    &:not(.table__rows--blank):hover,
    &--hover:not(.table__rows--blank) {
      &:not(.table__rows--blank) {
        background-color: #f1f7ff;

        & > td:first-of-type {
          border-left: solid 0.125rem #2b67b3;
        }

        & .table__rows__actions > button {
          display: unset;
        }
      }
    }

    & td:last-of-type {
      & > .table__rows__text {
        display: flex;
        justify-content: center;
        height: 20px;

        & > button {
          padding: 0;
          width: 24px;
          height: 24px;
        }
      }
    }

    & > td {
      // padding: 0.75rem;
      padding: unset;

      &.table--padding {
        &-input {
          padding: 0.375rem 0.5rem;
        }

        &-button {
          padding: 0.75rem 0.825rem;
        }
      }

      & > div.table__rows__text {
        display: flex;
        flex-grow: 1;
        align-items: center;
        gap: 8px;
      }
    }

    &__text {
      font-weight: normal;
      color: #000;
      // flex-grow: 1;
      font-size: 14px;
      line-height: 1.14;

      & > button {
        background-color: transparent;
      }
    }

    &__input {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      width: 100%;
      
      & > input,
      & > div,
      & > textarea,
      & > select {
        width: 100%;
      }
    }

    &__button {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      gap: 1rem;
    }

    &__actions {
      display: flex;
      justify-content: center;
      overflow: unset;
      align-items: center;
      flex-grow: 1;

      & > button {
        display: none;

        background-color: transparent;
        border: none;
        outline: none;

        padding: 0.5rem;

        &:hover {
          filter: invert(39%) sepia(14%) saturate(2760%) hue-rotate(174deg)
            brightness(93%) contrast(94%);
        }
      }
    }

    &--blank > td {
      border-bottom: solid 0.0625rem #ebf0ff;
    }
    &--border-less > td {
      border: none;
    }
  }

  &__cols {
    &:not(.table__cols--input) {
      padding: 0.75rem;
    }

    &.table__cols--input {
      padding: 0.375rem 0.5rem;

      & button.button {
        min-width: 20px;
        min-height: 20px;
      }
    }

    & > div {
      display: flex;
    }

    &--flex-grow {
      display: flex;
      flex-grow: 1;
    }

    &__text-align {
      &--center {
        text-align: center;
      }
    }
  }

  &__tfoot {
    min-height: 3.5rem;
    height: 3.5rem;

    & tr td {
      padding: unset;
    }
  }
}

// .table__thead > tr > th:nth-last-of-type(3) .table__thead__text,
// .table__rows:not(.table__rows--children) > td:nth-last-of-type(3) .table__rows__text {

// 	// text-align: right;
// }

// .table__rows.table__rows--children .table__thead > tr > th:nth-last-of-type(3) .table__thead__text,
// .table__rows.table__rows--children .table__rows:not(.table__rows--children) > td:nth-last-of-type(3) .table__rows__text {

// 	// text-align: left;
// }

// .table__thead > tr > th:nth-last-of-type(2) .table__thead__text,
// .table__rows:not(.table__rows--children) > td:nth-last-of-type(2) .table__rows__text {

// 	// text-align: center;
// }

// .table__rows:not(.table__rows--children) > td:nth-last-of-type(2) .table__rows__input,
// .table__rows:not(.table__rows--children) > td:nth-last-of-type(2) .table__rows__bar {

// 	display: flex;
// 	justify-content: center;
// 	width: 100%;
// }

// .table__rows:not(.table__rows--children) > td .table__rows__input,
// .table__rows:not(.table__rows--children) > td .table__rows__bar {

// 	width: 100%;

// 	& .select-custom,
// 	& .select-custom__text,
// 	& > input {

// 		width: 100%;
// 		height: 2.25rem;
// 		outline: none;
// 	}

// 	& > input {

// 		border: solid 1px #c4c4c4;
// 		padding: 0.625rem 0.5rem;

// 		&::placeholder {

// 			color: $--neutral-grey-medium;
// 		}
// 	}
// }

// .table__rows.table__rows--children thead.table__thead > tr:first-of-type,
// .table__rows.table__rows--children tbody > tr:first-of-type {

// 	background: transparent;
// }
