@import "../../scss/variables.scss";
.input {
  width: 100%;
  height: 40px;
  display: flex;
  flex-grow: 1;
  padding: 0.625rem 0.5rem;
  border-radius: 4px;
  border: solid 1px #c4c4c4;

  &__text-area {
    width: 100%;
    flex-grow: 0;
    padding: 0.75rem 0.5rem;
    border-radius: 0.25rem;
    border: solid 1px #c4c4c4;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    color: #373737;
  }

  &:active,
  &:focus {
    outline: none;
    border: solid 1px $--semantic-blue;
  }

  &--outline {
    &-red {
      &,
      &:active,
      &:focus {
        border: solid 1px $--neutral-wine-red !important;

        &::placeholder {
          color: $--neutral-wine-red;
        }
      }
    }
  }

  // 1
  // &.input-date {
  // 	position: relative;
  // 	// min-width: 100px;
  // 	// width: auto;
  // 	// width: 150px;
  // 	// height: 20px;
  // 	// color: white;
  // 	flex: 1 0 0%;

  // 	&.input-date.input-date--format {

  // 		&::-webkit-datetime-edit,
  // 		&::-webkit-inner-spin-button,
  // 		&::-webkit-clear-button {
  // 			display: none;
  // 			// content: attr(data-date);
  // 		}
  // 	}
  // }

  // &.input-date:after {
  // 	content: attr(data-date);
  // 	display: inline-block;
  // 	color: black;
  // 	flex: 1 0 0%;
  // }
  // // &.input-date
  // // &.input-date:before {
  // // 	content: attr(data-date);
  // // 	display: inline-block;
  // // 	color: black;
  // // 	flex: 1 0 0%;
  // // }

  // &.input-date::-webkit-calendar-picker-indicator {
  // 	position: absolute;
  // 	top: auto;
  // 	right: 0;
  // 	color: black;
  // 	opacity: 1;
  // }

  // 2
  // &.input-date::-webkit-datetime-edit,
  // &.input-date::-webkit-inner-spin-button,
  // &.input-date::-webkit-clear-button {
  // 	position: relative;
  // }

  // &.input-date::-webkit-datetime-edit-year-field,
  // &.input-date::-webkit-datetime-edit-month-field,
  // &.input-date::-webkit-datetime-edit-day-field {
  // 	position: absolute !important;
  // 	color:#000;
  // }

  // &.input-date::-webkit-datetime-edit-fields-wrapper {

  // 	display: flex;
  // 	gap: 28px;
  // 	padding: 0 20px;
  // 	align-items: center;
  // }
  // &.input-date.input-date--format::-webkit-datetime-edit-fields-wrapper {

  // 	display: flex;
  // 	gap: 18px;
  // 	padding: 0 20px;
  // 	align-items: center;
  // }

  // &.input-date::-webkit-datetime-edit-text {

  // 	&:nth-of-type(1) {

  // 		display: none;
  // 	}

  // 	&:nth-of-type(2) {

  // 		display: none;
  // 	}
  // }

  // &.input-date::-webkit-datetime-edit-day-field {
  // 	left: 0;
  // }

  // &.input-date::-webkit-datetime-edit-month-field {
  // 	left: 25px;
  // }

  // &.input-date::-webkit-datetime-edit-year-field {
  // 	left: 59px;
  // }

  // &.input-date.input-date--format::-webkit-datetime-edit-year-field {
  // 	left: 48px;
  // }
}
