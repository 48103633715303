@import '../../../scss/mixins';
@import '../../../scss/variables';
.camera-container {
  height: 100vh;
  background-color: #000;
  position: relative;
  .tip-container {
    position: absolute;
    bottom: 25%;
    left: 50%;
    transform: translateX(-50%);
    .tip-wrapper {
      display: flex;
      .tip-icon {
        margin-right: 5px;
        img {
          height: 50px;
          width: 50px;
          object-fit: contain;
        }
      }
      .tip-text {
        h5 {
          color: rgb(241, 255, 52);
          font-weight: 600;
        }
        p {
          color: #fff;
          font-size: 0.8rem;
        }
      }
    }
  }
  .success-msg {
    position: absolute;
    top: 2%;
    left: 50%;
    transform: translateX(-50%);
    .success-container {
      background-color: rgba(242, 242, 242, 0.2);
      padding: 10px;
      border-radius: 20px;
      & p {
        font-size: 1.3rem;
        font-weight: 700;
        color: rgb(0, 212, 250);
        text-align: center;
        width: 500px;
        margin: 0;
      }
    }
  }
  .test {
    position: absolute;
    top: 13vh;
    left: 50%;
    transform: translateX(-50%);
    & p {
      font-size: 1.3rem;
      font-weight: 700;
      color: rgb(50, 255, 101);
      text-align: center;
      width: 500px;
    }
    .dub {
      color: rgb(255, 30, 30);
    }
  }

  .btn-done {
    z-index: 2;
    position: absolute;
    min-width: 100vw;
    height: 50px;
    bottom: 0;
    left: 0;
    background-color: #2b67b3;
    font-weight: 600;
  }
  .element {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(242, 242, 242, 0.2);

    // -webkit-clip-path: polygon(
    //   0% 0%,
    //   0 100%,
    //   12% 100%,
    //   13% 36%,
    //   88% 36%,
    //   88% 63%,
    //   12% 63%,
    //   11% 100%,
    //   100% 100%,
    //   100% 0%
    // );

    // clip-path: polygon(
    //   0% 0%,
    //   0 100%,
    //   12% 100%,
    //   13% 36%,
    //   88% 36%,
    //   88% 63%,
    //   12% 63%,
    //   11% 100%,
    //   100% 100%,
    //   100% 0%
    // );
  }
}
@include mobile-min {
  p {
    font-size: 0.8rem !important;
    width: 100% !important;
  }
}
// @include mobile-above {
//   .element {
//     // -webkit-clip-path: polygon(
//     //   0% 0%,
//     //   0 100%,
//     //   12% 100%,
//     //   12% 30%,
//     //   88% 30%,
//     //   88% 68%,
//     //   11% 68%,
//     //   12% 100%,
//     //   100% 100%,
//     //   100% 0%
//     // );

//     // clip-path: polygon(
//     //   0% 0%,
//     //   0 100%,
//     //   12% 100%,
//     //   12% 30%,
//     //   88% 30%,
//     //   88% 68%,
//     //   11% 68%,
//     //   12% 100%,
//     //   100% 100%,
//     //   100% 0%
//     // );
//   }
// }
