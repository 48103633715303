@import '../../../../scss/variables';

.asset-info {
  z-index: 2;
  position: absolute;
  min-width: 100vw;
  bottom: 50px;
  left: 0;
  background-color: $--neutral-white;
  border-radius: 20px 20px 0 0;
  padding: 16px;

  .asset-list {
    margin: 0;
    padding: 0;
    max-height: 113px;
    overflow-y: scroll;
  }
}

.asset-info-title {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.14;
  color: $--neutral-primary-blue-dark;
}
