// Here you can add other styles
// * {
//   font-family: "Open Sans", sans-serif;
// }

/* Custom Selectbox Ant*/
.custom-select-box {
  .ant-select-selector {
    padding: 3px 12px !important;
    height: 40px !important;
    border-radius: 4px !important;
    border: 1px solid #d8dbe0 !important;
    background: transparent;
    background-image: url(../assets/icons/u-angle-down.svg);
    background-repeat: no-repeat;
    background-position-x: calc(100% - 8px);
    background-position-y: 50%;
    &::after {
      display: none;
    }
  }
  .ant-select-arrow {
    display: none;
  }
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #f1f7ff;
}

/* Custom DateRange Ant */
.custom-daterange {
  height: 40px;
  border-radius: 4px;
  .ant-picker-input {
    &:first-of-type {
      input {
        text-align: right;
      }
    }
  }
}
.badge-pill {
  border-radius: 100%;
  width: 20px;
  height: 20px;
  padding: 4px 2px;
}

.text-red {
  color: #af1427 !important;
}
