@import "../../../scss//variables";

.reporting-page {
	& .flex-gap--05 {
		display: flex;
		gap: 0.5rem;
	}

	&__table {
		& .flex-gap--05 {
			display: flex;
			gap: 0.5rem;
		}
		& .flex-gap--1 {
			display: flex;
			gap: 1rem;
		}

		& tr {
			& > th.table__cols,
			& > td.table__cols {
				// /* border-right: solid 1px #c4c4c4; */
				box-shadow: inset 0px 1px 0 0 #e4e4e4, inset 1px 0px 1px 0 #e4e4e4;
			}
			& > th:nth-of-type(1).table__cols {
				min-width: 128px;
				width: 15%;
			}
			& > th:nth-of-type(2).table__cols {
				min-width: 128px;
				width: calc(35% / 3);
			}
			& > th:nth-of-type(3).table__cols {
				min-width: 128px;
				width: calc(35% / 3);
			}
			& > th:nth-of-type(4).table__cols {
				min-width: 128px;
				width: calc(35% / 3);
			}
			& > th:last-of-type().table__cols {
				width: 50%;
			}

			& .table__cols .button-radio {
			}
		}

		& &__text {
			margin-top: 0.5rem;
		}
	}

	&__modal {
		.modal-dialog {
			margin: 1.75rem auto !important;
		}
		.ant-drawer-footer {
			gap: 0.25rem;
		}
		textarea {
			width: 100%;
			min-height: 80px;
			max-height: 80px;
			height: 80px;
			align-self: stretch;
			flex-grow: 0;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: flex-start;
			gap: 10px;
			padding: 12px 10px;
			border-radius: 4px;
			border: solid 1px $--neutral-grey-light;
			outline: none;
		}
		.refresh-signature {
			height: 2rem;
			width: 60px;
			padding: 0.5rem 0 0.5rem 0.5rem;
			cursor: pointer;
			font-size: 12px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.33;
			letter-spacing: normal;
			color: #2b67b3;
			display: flex;
			align-items: center;

			&:hover img {
				animation-name: spin;
				animation-duration: 5000ms;
				animation-iteration-count: infinite;
				animation-timing-function: linear;
			}
		}
		.signature-content {
			.sheader {
				display: flex;
				justify-content: space-between;
				width: 100%;
			}

			& .signature-area {
				width: 100%;
				min-height: 300px;
				grid-gap: 10px;
				gap: 10px;
				padding: 12px 10px;
				border-radius: 4px;
				border: solid 1px #c4c4c4;
				display: flex;
				justify-content: center;
				align-items: center;

				& img {
					max-width: 100%;
					object-fit: contain;
				}
			}

			& a.resign {
				text-decoration: underline !important;
			}
		}
		.dropdown div.selected-value {
			height: 40px !important;
		}
		.dropdown .search {
			position: absolute !important;
			z-index: 1001 !important;
		}
		.dropdown .options {
			top: 90px !important;
		}
	}
	&__label {
		&__text {
			height: 2rem;
			flex-grow: 1;
			font-size: 12px;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.33;
			letter-spacing: normal;
			text-align: left;
			color: $--neutral-grey;
			padding: 0.5rem 0;
			margin: 0.5rem 0;

			overflow: hidden;
			text-overflow: ellipsis &--disabled {
				color: $--neutral-grey-medium;
			}
		}
	}
	&__field {
		height: 2rem;
		margin-bottom: 0.5rem;

		&--mobile {
			height: 44px;
			margin: 1rem 0 19px;
		}

		&__text {
			height: 1rem;
			flex-grow: 0;
			font-size: 12px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.5;
			letter-spacing: normal;
			text-align: left;
			color: $--neutral-grey;

			&--disabled {
				color: $--neutral-grey-medium;
			}

			&--emphasis {
				font-weight: 600;
			}
		}

		& img,
		&--mobile img {
			width: 20px;
			height: 20px;
			object-fit: contain;
			clip-path: circle(10px at center);
		}
	}

	& .table .table__cols--flex.table__thead__text {
		// text-align: center;
	}

	& .scrollable {
		&::-webkit-scrollbar {
			width: 4px;
			background-color: transparent;
		}

		&::-webkit-scrollbar-button {
			display: none;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 10px;
			-webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
			background-color: #ccc;
			outline: 1px solid #e7eaf0;
		}

		&::-webkit-scrollbar-track {
			border-radius: 10px;
			background-color: transparent;
		}
	}

	.refresh-signature {
		height: 2rem;
		width: 60px;
		padding: 0.5rem 0 0.5rem 0.5rem;
		cursor: pointer;
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.33;
		letter-spacing: normal;
		color: #2b67b3;
		display: flex;
		align-items: center;

		&:hover img {
			animation-name: spin;
			animation-duration: 5000ms;
			animation-iteration-count: infinite;
			animation-timing-function: linear;
		}
	}
	img.printing {
		animation-name: spin;
		animation-duration: 5000ms;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
	}
	.signature-content {
		.sheader {
			display: flex;
			justify-content: space-between;
			width: 100%;
		}

		& .signature-area {
			width: 100%;
			min-height: 300px;
			grid-gap: 10px;
			gap: 10px;
			padding: 12px 10px;
			border-radius: 4px;
			border: solid 1px #c4c4c4;
			display: flex;
			justify-content: center;
			align-items: center;

			& img {
				max-width: 100%;
				object-fit: contain;
			}
		}

		& a.resign {
			text-decoration: underline !important;
		}
	}
}

.ant-select-selector,
.ant-select-selection-search-input {
	border-radius: 4px !important;
	height: 40px !important;
}
.ant-select-selection-item {
	display: flex;
	align-items: center;
	padding: 0.5rem 0;
}

.reporting-page__modal_content {
	padding-bottom: 40px;
}

.modal-footer {
	& .button.button__outline--primary {
		& .printing {
			animation-name: rotate;
			animation-duration: 5s;
			animation-iteration-count: infinite;
			animation-timing-function: linear;
			@keyframes rotate {
				from {
					transform: rotate(-360deg);
				}
				to {
					transform: rotate(360deg);
				}
			}
		}
	}
}

.table__cols--flex.flex.justify-center.align-center.flex-grow > .table__thead__text.table__thead__text--text-center {
	width: 120px;
	flex-grow: 0;
}
