@import "./variables";

.input {

	&--antd-date {
		
		width: 100%;
		height: 40px;
		display: flex;
		flex-grow: 1;
		padding: 0.625rem 0.5rem;
		border-radius: 4px;
		border: solid 1px #c4c4c4;

		& > .ant-picker-input {

			& > input::-webkit-input-placeholder {
				
				color: $--neutral-grey-medium;
			}
		}

		&--outline-red, 
		&--outline-red:active, 
		&--outline-red:focus {

			border: solid 1px $--neutral-wine-red !important;
			color: $--neutral-wine-red;
			
			&.ant-picker.input--antd-date.input--antd-date--outline-red 
			> .ant-picker-input > span.ant-picker-suffix, 
			&.ant-picker.input--antd-date.input--antd-date--outline-red 
			> .ant-picker-input > input::-webkit-input-placeholder {
				
				color: $--neutral-wine-red;
			}
		}

	}
}

input, .input {

	&::placeholder {
				
		color: $--neutral-grey-medium;
	}
}